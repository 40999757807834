<template>
  <div>
    <h3 class="mb-4 color-primary-light">Please remove your product from iCloud</h3>
    <div class="row mb-4">
      <div class="col-lg-7">
        <div class="d-flex align-items-center">
          <img
            class="find-my-device"
            src="@/assets/images/find-my-icon.png"
            alt="Find My Device Icon"
          />
          <h4 class="color-primary">
            To speed up the assessment of your <br />
            Apple product follow these steps.
          </h4>
        </div>

        <ul class="list-unstyled">
          <li>
            <strong class="step">Step 1</strong>
            <h4>Go to <span class="color-red">icloud.com/find</span></h4>
          </li>
          <li>
            <strong class="step">Step 2</strong>
            <h4>
              Sign in with the Apple ID and password <br />
              you used to <span class="color-red">set up your device</span>
            </h4>
          </li>
          <li>
            <strong class="step">Step 3</strong>
            <h4>
              In Find My iPhone on iCloud.com, click <span class="color-red">All Devices</span>
              <br />
              and then select the product you want to remove
            </h4>
          </li>
          <li>
            <strong class="step">Step 4</strong>
            <h4>Click <span class="color-red">Remove from Account</span></h4>
          </li>
        </ul>
      </div>
      <div class="col-lg-5">
        <img class="icloud" src="@/assets/images/icloud.png" alt="iCloud" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <router-link class="btn btn-next" :to="pages.packageDevice.route"
          ><strong>Next</strong></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { pages } from '@/shared';

export default {
  computed: {
    pages() {
      return pages['revive-apple'].children;
    },
  },
};
</script>

<style scoped lang="scss">
.find-my-device {
  width: 100%;
  max-width: 100px;
  margin-right: 20px;
}

.icloud {
  width: 100%;
}

.step {
  font-size: 14px;
}

ul {
  margin-top: 20px;

  li {
    margin-bottom: 20px;

    h4 {
      margin-bottom: 0;
    }
  }
}
</style>
